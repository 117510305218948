<template>
  <div class="questions">
    <h2 class="mb-3 mb-sm-5">
      {{ i18n.title }}
    </h2>
    <ul class="questions__ul">
      <li
        v-for="(question, i) in i18n.list"
        :key="'question' + i"
        :class="[
          'question',
          {
            _open: openIndex === i,
          },
        ]"
        @click="onClickQuestion(i)"
      >
        <div class="questions-btn">
          <h4>{{ question.q }}</h4>
          <Icon type="arrow-right" />
        </div>
        <p class="question__answer">
          {{ question.a }}
        </p>
      </li>
    </ul>
  </div>
</template>

<style lang="css">
.questions {
  padding: 6.2rem 1.5rem 8.1rem;
}

.questions__ul {
  padding: 0.9rem 0;
}

.question {
  border: 1px solid #000000;
  margin-bottom: -1px;
  padding: 2rem 2.5rem 1.9rem;
  cursor: pointer;
}

.question .icon {
  transform: rotate(90deg);
  transition: transform 0.15s ease-out;
}

.question._open {
  background-color: #ffd600;
}

.question._open .icon {
  transform: rotate(-90deg);
}

.questions-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.question__answer {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.03em;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.question._open .question__answer {
  padding: 1rem 0;
  max-height: 500px;
  transition: max-height 0.15s ease-in;
}

@media (max-width: 768px) {
  .questions {
    padding: 4rem 1.5rem 3.1rem;
  }

  .question {
    padding: 1.2rem 1.2rem 1.2rem;
  }

  .question__answer {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  .question._open .question__answer {
    padding: 0.6rem 0;
  }

  .questions .icon-container {
    margin-left: 10px;
  }
}
</style>

<script>
export default {
  name: "Questions",
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      openIndex: null,
    };
  },
  methods: {
    onClickQuestion(index) {
      if (this.openIndex === index) {
        this.openIndex = null;
        return;
      }

      this.openIndex = index;
    },
  },
};
</script>
